import React from "react"
import Block from "../ui/Block"
import styled from "styled-components"

const Citation = styled.div`
  text-align: center;
  font-style: italic;
  font-size: 1.25rem;
  background-color: #f0f0f0;
  padding: 1rem;

  & p:last-child {
    margin-bottom: 0;
  }
`

export default ({ children }) => {
  return (
    <Block wide>
      <Citation>{children}</Citation>
    </Block>
  )
}
