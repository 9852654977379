import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import styled from "styled-components"
import Block from "../../ui/Block"

const GalleryContainer = styled.div`
  background-color: #000;
`

const GalleryTitle = styled.h2`
  text-align: center;
  color: #fff;
  font-size: 2.5rem;
  padding: 1.5rem 0 0 0;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  grid-auto-rows: 16rem;
  grid-gap: 0.5rem;
  @media (min-width: 1280px) {
    grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
    grid-auto-rows: 24rem;
  }
`

const Tile = styled.div`
  position: relative;
  cursor: pointer;
  overflow: hidden;
`

const tiledImgStyles = {
  style: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    transition: "transform 0.5s, filter 0.25s",
  },
}

export default ({ pictures, pictureViewer }) =>
  pictures.length > 1 && (
    <Block fullWidth>
      <GalleryContainer>
        <GalleryTitle>Photos</GalleryTitle>
        <Grid className="galleryGrid">
          {pictures.map(picture => (
            <Tile
              key={picture.name}
              onClick={e => {
                pictureViewer.open(picture)
                e.stopPropagation()
              }}
            >
              <Img
                fixed={picture.childImageSharp.fixed}
                alt={picture.name}
                {...tiledImgStyles}
              />
            </Tile>
          ))}
        </Grid>
      </GalleryContainer>
    </Block>
  )
