import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import styled from "styled-components"
import IconClose from "../../ui/IconClose.js"
import IconPrev from "../../ui/IconPrev"
import IconNext from "../../ui/IconNext"
import IconMap from "../../ui/IconMap.js"

const FullScreenContainer = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
`

const ButtonBar = styled.nav`
  position: absolute;
  width: 100%;
  margin: 0 -20px;
  height: 40px;
  bottom: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
`

const InfoBar = styled(ButtonBar)`
  bottom: 40px;
  height: 24px;
`

const Button = styled.button`
  height: 40px;
  background-color: #000;
  color: #fff;
  border: none;
  text-align: center;
  padding: 0 20px;
`

const ArrowButton = styled(Button)`
  padding: 7px 20px 0;
  cursor: pointer;
`
const IndexButton = styled(Button)`
  width: 120px;
`

const InfoBarContainer = styled(Button)`
  background-color: transparent;
  padding-top: 2px;
  font-size: 0.75rem;
  height: 24px;
  text-shadow: #000 0px 0px 8px;
`

const LocationButton = styled.a`
  padding-left: 8px;
`

const CloseButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 10px 1px 10px;
  height: auto;
  cursor: pointer;
`

const fullScreenImgStyles = {
  style: {
    width: "100%",
    height: "100%",
  },
}

export default ({ pictures, currentPicture, pictureViewer }) => {
  const dateTaken =
    currentPicture.childImageSharp.fields &&
    currentPicture.childImageSharp.fields.exif.meta.dateTaken
      ? currentPicture.childImageSharp.fields.exif.meta.dateTaken.replace(
          /([0-9]{4})-([0-9]{2})-([0-9]{2})T([0-9]{2}):([0-9]{2}):.*/i,
          "$3/$2/$1 $4:$5"
        )
      : undefined

  const gpsData =
    currentPicture.childImageSharp.fields &&
    currentPicture.childImageSharp.fields.exif.gps.latitude
      ? {
          lat: currentPicture.childImageSharp.fields.exif.gps.latitude,
          lng: currentPicture.childImageSharp.fields.exif.gps.longitude,
        }
      : undefined

  const currentPicturePosition =
    pictures.findIndex(pic => pic.id === currentPicture.id) + 1
  const pictureCount = pictures.length

  return (
    <FullScreenContainer
      onClick={e => {
        e.stopPropagation()
        pictureViewer.close()
      }}
    >
      <Img
        fluid={currentPicture.childImageSharp.fluid}
        alt={currentPicture.name}
        fadeIn={false}
        objectFit="contain"
        objectPosition="50% 50%"
        {...fullScreenImgStyles}
      />

      <ButtonBar>
        <ArrowButton
          onClick={e => {
            e.stopPropagation()
            pictureViewer.prev()
          }}
          disabled={currentPicturePosition === 1}
        >
          <IconPrev />
        </ArrowButton>
        <IndexButton onClick={e => e.stopPropagation()}>
          {currentPicturePosition}/{pictureCount}
        </IndexButton>
        <ArrowButton
          onClick={e => {
            e.stopPropagation()
            pictureViewer.next()
          }}
          disabled={currentPicturePosition === pictureCount}
        >
          <IconNext />
        </ArrowButton>
      </ButtonBar>

      {(dateTaken || gpsData) && (
        <InfoBar>
          <InfoBarContainer>
            {dateTaken}
            {gpsData && (
              <LocationButton
                href={`http://www.google.com/maps/place/${gpsData.lat},${gpsData.lng}`}
                target="blank"
              >
                <IconMap />
              </LocationButton>
            )}
          </InfoBarContainer>
        </InfoBar>
      )}

      <CloseButton
        onClick={e => {
          pictureViewer.close()
          e.stopPropagation()
        }}
      >
        <IconClose />
      </CloseButton>
    </FullScreenContainer>
  )
}
