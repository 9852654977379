import React from "react"

export default () => (
  <svg width="10" height="14" viewBox="0 0 10 14" fill="none">
    <g id="place">
      <path
        id="place_2"
        d="M0 5.03338C0 2.25064 2.23571 0 5 0C7.76429 0 10 2.25064 10 5.03338C10 8.03184 6.84286 12.1664 5.55 13.7411C5.26429 14.0863 4.74286 14.0863 4.45714 13.7411C3.15714 12.1664 0 8.03184 0 5.03338ZM3.21429 5.03339C3.21429 6.02568 4.01429 6.83102 5 6.83102C5.98571 6.83102 6.78571 6.02568 6.78571 5.03339C6.78571 4.04109 5.98571 3.23575 5 3.23575C4.01429 3.23575 3.21429 4.04109 3.21429 5.03339Z"
        fill="currentColor"
      />
    </g>
  </svg>
)
