import React from "react"

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g id="close">
      <path
        id="close_2"
        d="M18.3 5.70999C18.1132 5.52273 17.8595 5.4175 17.595 5.4175C17.3305 5.4175 17.0768 5.52273 16.89 5.70999L12 10.59L7.11 5.69999C6.92317 5.51273 6.66952 5.4075 6.405 5.4075C6.14048 5.4075 5.88683 5.51273 5.7 5.69999C5.31 6.08999 5.31 6.71999 5.7 7.10999L10.59 12L5.7 16.89C5.31 17.28 5.31 17.91 5.7 18.3C6.09 18.69 6.72 18.69 7.11 18.3L12 13.41L16.89 18.3C17.28 18.69 17.91 18.69 18.3 18.3C18.69 17.91 18.69 17.28 18.3 16.89L13.41 12L18.3 7.10999C18.68 6.72999 18.68 6.08999 18.3 5.70999Z"
        fill="currentColor"
      />
    </g>
  </svg>
)
