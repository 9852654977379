import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

const Container = styled.figure`
  margin: 0 0 1.666rem;
  cursor: pointer;
`

const Legend = styled.figcaption`
  font-size: 0.75rem;
  margin: 3px 0;
  text-align: center;
  font-style: italic;
`

const NotFound = styled.div`
  background-color: #f0f0f0;
  font-style: italic;
  padding: 0.5rem 1rem;
`

export default ({ pictures, src, legend, pictureViewer }) => {
  const picture = pictures.find(pic => pic.name === src || pic.base === src)

  return (
    <Container
      onClick={e => {
        pictureViewer.open(picture)
        e.stopPropagation()
      }}
    >
      {picture && (
        <>
          <Img
            fluid={picture.childImageSharp.fluid}
            alt={legend || picture.name}
          />
          {legend && <Legend>{legend}</Legend>}
        </>
      )}
      {!picture && (
        <NotFound>
          {" "}
          <span role="img" aria-label="warning">
            ⚠️
          </span>{" "}
          Picture not found "{src}".
        </NotFound>
      )}
    </Container>
  )
}
