import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/Layout"
import { Helmet } from "react-helmet"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Header from "../components/article/Header"
import PictureGallery from "../components/article/gallery/Gallery"
import Viewer from "../components/article/gallery/Viewer"
import { MDXProvider } from "@mdx-js/react"
import styled from "styled-components"
import Picture from "../components/article/Picture"
import PrevNext from "../components/article/PrevNext"
import Tags from "../components/article/Tags"
import Citation from "../components/article/Citation"
import EmbeddedMap from "../components/article/EmbeddedMap"

const Body = styled.div`
  & > p:first-of-type:first-letter {
    float: left;
    font-size: 4.8em;
    line-height: 1;
    padding: 0 0.1em 0 0;
    /* Necessary for Firefox */
    font-weight: 700;

    /* fix initial letter top alignment on firefox */
    @supports (-moz-appearance: none) {
      padding-top: 0.12em;
    }
  }

  & > p:first-of-type:first-line {
    font-weight: 700;
  }
`

const sortPictures = pictures =>
  pictures.slice().sort((a, b) => {
    const aDateTaken =
      a.childImageSharp.fields && a.childImageSharp.fields.exif
        ? a.childImageSharp.fields.exif.meta.dateTaken
        : null
    const bDateTaken =
      b.childImageSharp.fields && b.childImageSharp.fields.exif
        ? b.childImageSharp.fields.exif.meta.dateTaken
        : null

    if (aDateTaken && bDateTaken && aDateTaken !== bDateTaken) {
      return aDateTaken.localeCompare(bDateTaken)
    }
    return a.name.localeCompare(b.name)
  })

const getPreviousPicture = (currentPicture, pictures) => {
  const index = pictures.findIndex(pic => pic.id === currentPicture.id)
  return index > 0 ? pictures[index - 1] : undefined
}

const getNextPicture = (currentPicture, pictures) => {
  const index = pictures.findIndex(pic => pic.id === currentPicture.id)
  return index < pictures.length - 1 ? pictures[index + 1] : undefined
}

export default ({ data, pageContext }) => {
  const article = data.mdx
  const { nextArticle, prevArticle } = pageContext
  const pictures = sortPictures(data.allFile.nodes)

  const [currentPicture, setCurrentPicture] = React.useState(undefined)

  const pictureViewer = {
    close: () => setCurrentPicture(undefined),
    open: pic => setCurrentPicture(pic),
    prev: () => {
      const pic = getPreviousPicture(currentPicture, pictures)
      pic && setCurrentPicture(pic)
    },
    next: () => {
      const pic = getNextPicture(currentPicture, pictures)
      pic && setCurrentPicture(pic)
    },
  }

  /** Handle keyboard navigation */
  React.useEffect(() => {
    const handleKeyPress = e => {
      if (
        ["arrowright", "arrowdown", " ", "enter"].includes(e.key.toLowerCase())
      ) {
        pictureViewer.next()
        e.preventDefault()
        e.stopPropagation()
      }
      if (["arrowleft", "arrowup", "backspace"].includes(e.key.toLowerCase())) {
        pictureViewer.prev()
        e.preventDefault()
        e.stopPropagation()
      }
      if (["escape", "q"].includes(e.key.toLowerCase())) {
        pictureViewer.close()
        e.preventDefault()
        e.stopPropagation()
      }
    }

    window.addEventListener("keydown", handleKeyPress)

    return () => {
      window.removeEventListener("keydown", handleKeyPress)
    }
  }, [pictureViewer])

  const shortcodes = {
    Pic: props => (
      <Picture pictures={pictures} pictureViewer={pictureViewer} {...props} />
    ),
    Citation: props => <Citation>{props.children}</Citation>,
    Map: props => <EmbeddedMap mapId={props.mapId} />,
  }

  return (
    <Layout picture={article.frontmatter.mainPicture}>
      <Helmet title={article.frontmatter.title} />
      <article>
        <Header {...article.frontmatter} {...article.fields} />

        <Body>
          <MDXProvider components={shortcodes}>
            <MDXRenderer>{article.body}</MDXRenderer>
          </MDXProvider>
        </Body>

        <Tags tags={article.frontmatter.tags} />

        <PrevNext prev={prevArticle} next={nextArticle} />

        {!article.frontmatter.noGallery && (
          <PictureGallery pictures={pictures} pictureViewer={pictureViewer} />
        )}
        {currentPicture && (
          <Viewer
            pictures={pictures}
            currentPicture={currentPicture}
            pictureViewer={pictureViewer}
          />
        )}
      </article>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!, $dirName: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      fields {
        isPublished
        isPublic
      }
      frontmatter {
        title
        date(formatString: "dddd D MMMM YYYY", locale: "fr-FR")
        dateEnd(formatString: "dddd D MMMM YYYY", locale: "fr-FR")
        place
        country
        tags
        mainPicture {
          base
          childImageSharp {
            fluid(
              maxWidth: 1400
              maxHeight: 570
              srcSetBreakpoints: [400, 960]
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        noGallery
      }
    }
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: $dirName }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        id
        name
        base
        childImageSharp {
          fields {
            exif {
              meta {
                dateTaken
              }
              gps {
                latitude
                longitude
              }
            }
          }
          fluid(maxWidth: 1280, srcSetBreakpoints: [400, 960]) {
            ...GatsbyImageSharpFluid
          }
          fixed(width: 500) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`
