import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import kebabCase from "lodash/kebabCase"

const Container = styled.nav`
  margin: 0 0 1.666rem;
`

const Caption = styled.span`
  display: block;
  font-size: 0.75rem;
  text-transform: uppercase;
  color: hsla(0, 0%, 0%, 0.6);
`

const TagList = styled.div`
  display: block;
`

const StyledLink = styled(Link)`
  display: inline-block;
  margin: 0 0.5rem 0 0;
`

export default ({ tags }) => {
  return (
    tags && (
      <Container>
        <Caption>Catégories</Caption>
        <TagList>
          {tags.map(tag => (
            <StyledLink to={`/tags/${kebabCase(tag)}`} key={tag}>
              {tag}
            </StyledLink>
          ))}
        </TagList>
      </Container>
    )
  )
}
