import React from "react"
import Block from "../ui/Block"

export default ({ mapId, title }) => (
  <Block wide>
    <iframe
      src={`https://www.google.com/maps/d/embed?mid=${mapId}`}
      width="100%"
      height="480"
      title={title ? title : "Carte"}
    ></iframe>
  </Block>
)
