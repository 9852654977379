import React from "react"
import styled from "styled-components"
import FormattedDateRange from "../ui/FormattedDateRange"
import ArticleMarker from "../ui/ArticleMarker"

const ArticleHeader = styled.hgroup`
  margin: 3rem 0;
`

const Title = styled.h1`
  margin: 0 0 0.5rem;
  font-size: 2.3rem;
`

const DateAndPlace = styled.h3`
  font-family: "Patua One", sans-serif;
  font-size: 18px;
  margin: 0;
`

export default ({
  title,
  isPublished,
  isPublic,
  date,
  dateEnd,
  place,
  country,
}) => (
  <ArticleHeader>
    <Title>
      {title}
      <ArticleMarker isPublished={isPublished} isPublic={isPublic} />
    </Title>
    <DateAndPlace>
      {place}
      {place && country && <>, </>}
      {country}
      {(place || country) && <> – </>}
      <FormattedDateRange from={date} to={dateEnd} useDayOfWeek />
    </DateAndPlace>
  </ArticleHeader>
)
