import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Block from "../ui/Block"
import IconPrev from "../ui/IconPrev"
import IconNext from "../ui/IconNext"

const Container = styled.nav`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: stretch;
`

const LinkContainer = styled.div`
  width: 40%;
`

const PrevLinkContainer = styled(LinkContainer)`
  margin-left: 32px;
`

const NextLinkContainer = styled(LinkContainer)`
  margin-right: 32px;
  text-align: right;
`

const StyledLink = styled(Link)`
  text-decoration: none;

  &:hover .caption {
    color: hsla(0, 0%, 0%, 0.6);
  }
`

const Caption = styled.p`
  margin-bottom: 0.25rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  color: hsla(0, 0%, 0%, 0.6);
`
const ArticleTitle = styled.span`
  display: inline-flex;
  align-items: center;
  text-decoration: underline;

  & svg {
    flex-shrink: 0;
  }
`

const ArticleTitlePrev = styled(ArticleTitle)`
  margin-left: -32px;

  & svg {
    margin-right: 8px;
  }
`

const ArticleTitleNext = styled(ArticleTitle)`
  margin-right: -32px;

  & svg {
    margin-left: 8px;
  }
`

export default ({ prev, next }) => {
  return (
    <Block wide moreSpacing>
      <Container>
        <PrevLinkContainer>
          {prev && (
            <StyledLink to={prev.fields.slug}>
              <Caption className="caption">Plus récent</Caption>
              <ArticleTitlePrev>
                <IconPrev />
                {prev.frontmatter.title}
              </ArticleTitlePrev>
            </StyledLink>
          )}
        </PrevLinkContainer>

        <NextLinkContainer>
          {next && (
            <StyledLink to={next.fields.slug}>
              <Caption className="caption">Plus ancien</Caption>
              <ArticleTitleNext>
                {next.frontmatter.title}
                <IconNext />
              </ArticleTitleNext>
            </StyledLink>
          )}
        </NextLinkContainer>
      </Container>
    </Block>
  )
}
